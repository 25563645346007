import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { Link, useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import {
  Nav,
  NavItem,
  NavLink,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupAddon
} from 'reactstrap';

import { Layout, MembersList, Map, SEO, Image } from '../components';
import useMembers from '../utils/useMembers';
import messages from '../messages/community';
import coverPic from '../assets/images/community-header.png';
import searchIcon from '../assets/images/search-icon.png';
import seeAllArrow from '../assets/images/arrow-right.png';
import useWindowDimensions from '../utils/useWindowDimensions';

const Community = ({
  data: {
    allMembers: { edges: allMembers }
  }
}) => {
  const intl = useIntl();
  const [countryCode, setCountryCode] = useState('');
  const [mapTooltip, setMapTooltip] = useState('');
  const [searchInput, setSearchInput] = useState('');

  const members = useMembers(countryCode, allMembers, searchInput).slice(0, 9);

  const { width } = useWindowDimensions();
  const smallLimit = 1200;
  const [isSmallScreen, setIsSmallScreen] = useState(width < smallLimit);

  useEffect(() => {
    setIsSmallScreen(width < smallLimit);
  }, [width]);

  return (
    <Layout fluid background={false} pure>
      <SEO title={intl.formatMessage(messages.community)} />
      <Row
        className="mx-0 p-O community-header mb-5"
        style={{ backgroundImage: `url(${coverPic})` }}
      >
        <div className="d-flex align-items-center w-100 justify-content-center">
          <div className="text-center community-header-title">
            {intl.formatMessage(messages.headerQuote)}
          </div>
        </div>
      </Row>
      <div className="px-5 container">
        <Row>
          <Col>
            <div className="activities-tabs-and-search mb-5">
              <Nav tabs className="activities-tabs">
                <NavItem>
                  <NavLink active className="community-nav-link">
                    {intl.formatMessage(messages.community)}
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="8">
            <div className="community-ipsum mb-3">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has
            </div>
          </Col>
          <Col md="4" xs="12">
            <div
              className={`d-flex ${
                isSmallScreen ? '' : 'justify-content-center'
              }`}
            >
              <div className="njano-community">
                {intl.formatMessage(messages.njanoCommunity)}
              </div>
            </div>
          </Col>
        </Row>
        {isSmallScreen && (
          <Row>
            <Col>
              <InputGroup className="activities-search-box mt-5">
                <InputGroupAddon addonType="prepend" className="search-icon">
                  <Image src={searchIcon} alt="Hivos logo" width={18} />
                </InputGroupAddon>
                <Input
                  type="text"
                  value={searchInput}
                  onChange={e => {
                    setSearchInput(e.target.value);
                  }}
                  placeholder={intl.formatMessage(messages.searchInput)}
                  className="search-field"
                />
              </InputGroup>
            </Col>
          </Row>
        )}
        <Row className="mt-5">
          {isSmallScreen && (
            <Col xs="12">
              <Map
                setCountryCode={setCountryCode}
                mapTooltip={mapTooltip}
                setMapTooltip={setMapTooltip}
                countryCode={countryCode}
              />
            </Col>
          )}
          <Col md="5" xs="12">
            {!isSmallScreen && (
              <InputGroup className="activities-search-box">
                <InputGroupAddon addonType="prepend" className="search-icon">
                  <Image src={searchIcon} alt="Hivos logo" width={18} />
                </InputGroupAddon>
                <Input
                  type="text"
                  value={searchInput}
                  onChange={e => {
                    setSearchInput(e.target.value);
                  }}
                  placeholder={intl.formatMessage(messages.searchInput)}
                  className="search-field"
                />
              </InputGroup>
            )}
            {members && members.length > 0 && (
              <>
                <Link
                  to={`/community/members/${countryCode.toLowerCase()}`}
                  className="d-flex see-all-members-link my-5 pl-2"
                >
                  {intl.formatMessage(messages.seeAll)}
                  <Image src={seeAllArrow} alt="see all" className="ml-3" />
                </Link>
                <MembersList members={members} />
              </>
            )}
          </Col>
          {!isSmallScreen && (
            <Col md="7">
              <Map
                setCountryCode={setCountryCode}
                mapTooltip={mapTooltip}
                setMapTooltip={setMapTooltip}
                countryCode={countryCode}
              />
            </Col>
          )}
        </Row>
      </div>
    </Layout>
  );
};
export default Community;
Community.propTypes = {
  data: PropTypes.shape({
    allMembers: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
            slug: PropTypes.string,
            name: PropTypes.string,
            description: PropTypes.string,
            memberFields: PropTypes.shape({
              country: PropTypes.string
            }),
            avatar: PropTypes.shape({
              wordpress_96: PropTypes.string,
              wordpress_48: PropTypes.string
            })
          })
        })
      )
    })
  }).isRequired
};
export const query = graphql`
  {
    allMembers: allWpUser(filter: { slug: { ne: "admin" } }) {
      edges {
        node {
          id
          slug
          name
          description
          memberFields {
            country
            memberSince
          }
          avatar {
            url
          }
        }
      }
    }
  }
`;
