import { useEffect, useState } from 'react';

export default function useMembers(
  countryCode = '',
  allMembers = [],
  searchInput = ''
) {
  const [members, setMembers] = useState([]);
  useEffect(() => {
    if (searchInput) {
      setMembers(
        allMembers.filter(
          ({ node: member }) =>
            member.memberFields &&
            member.memberFields.country.toLowerCase() ===
              countryCode.toLowerCase() &&
            member.name.toLowerCase().includes(searchInput.toLowerCase())
        )
      );
    } else if (countryCode) {
      setMembers(
        allMembers.filter(
          ({ node: member }) =>
            member.memberFields &&
            member.memberFields.country.toLowerCase() ===
              countryCode.toLowerCase()
        )
      );
    } else {
      setMembers(allMembers.sort(() => 0.5 - Math.random()));
    }
  }, [countryCode, searchInput, allMembers]);

  return members;
}
