import { defineMessages } from 'react-intl';

export const scope = 'community';

export default defineMessages({
  community: {
    id: `${scope}.community`,
    defaultMessage: 'Community'
  },
  njanoCommunity: {
    id: `${scope}.njanoCommunity`,
    defaultMessage: 'JOIN OUR NJANO Community'
  },
  headerQuote: {
    id: `${scope}.headerQuote`,
    defaultMessage:
      '"There is no power for change greater than a community discovering what it cares about."'
  },
  searchInput: {
    id: `${scope}.searchInput`,
    defaultMessage: 'Search keywords...'
  },
  seeAll: {
    id: `${scope}.seeAll`,
    defaultMessage: 'See all members'
  },
  bio: {
    id: `${scope}.bio`,
    defaultMessage: 'Bio'
  },
  quote: {
    id: `${scope}.quote`,
    defaultMessage: 'Quote'
  },
  articles: {
    id: `${scope}.articles`,
    defaultMessage: 'Articles'
  }
});
